import React from 'react'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'

import { withDefaultLayout } from 'layouts/with-default-layout'
import { getZIndex, greaterThan } from 'utils/styles'
import { Container, ProjectsTile } from 'components'

const FrontTile = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;
  z-index: ${getZIndex('tile')};
`

const Name = styled.h2`
  text-transform: uppercase;
  margin: 0;
  color: #616161;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Gotham';
`

const Heading = styled.h1`
  color: #fff;
  font-size: 32px;
  line-height: 44px;
  font-weight: 400;
  margin: 0;
  word-break: break-word;

  ${greaterThan.medium`
    font-size: 42px;
    line-height: 48px;
  `}
`

const ScrollDown = styled(Link)`
  color: #616161;
  position: absolute;
  left: 0;
  bottom: 40px;
  transition: color 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`

const Index = ({ data: { homepage, instagram }, pageContext: { language } }) => {

  const projects = instagram.nodes.map(item => ({
    id: item.id,
    url: item.permalink,
    image: item.media_type === 'VIDEO' ? item.thumbnail_url : item.media_url,
    text: `${item.caption.slice(0, 70)}...`,
  })).slice(0, 12)

  return (
    <>
      <FrontTile>
        <Name>{homepage.firstHeading}</Name>
        <Heading dangerouslySetInnerHTML={{ __html: homepage.secondHeading }} />
        <ScrollDown to='#projects' >{homepage.scrollToProjects}</ScrollDown>
      </FrontTile>

      <div id='projects' >
          <ProjectsTile projects={projects} language={language} instagramLink={true} />
      </div>
    </>
  )
}

export default withDefaultLayout(Index)

export const query = graphql`
  query HomePageQuery($language: String!) {
    homepage: contentfulHomepage(node_locale: { eq: $language }) {
      firstHeading
      secondHeading
      scrollToProjects
    }
    instagram: allInstagramContent (limit: 12) {
      nodes {
        id
        media_url
        permalink
        caption
        media_type
        thumbnail_url
      }
    }
    footer: contentfulFooter(node_locale: { eq: $language }) {
      heading
      firstAddress {
        firstAddress
      }
      secondAddress {
        secondAddress
      }
      writeToMe
      instagramUrl
      facebookUrl
    }
  }
`
